import React, { useContext, useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import Span from '../../../../atoms/spans/Span';
import Button from '../../../../atoms/buttons/Button';
import { StateHelper } from '../../../helper/state';
import { AppContext } from '../../../../../contexts/AppContext';
import Menu, { MENU_CATEGORY } from '../../../../organisms/menu/Menu';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import { errorKeys } from '../../../../../constants/documentCustomization';
import { ContactService } from '../../../../../services';
import { BillingDataHelper } from './helper';
import Input from '../../../../atoms/inputs/Input';
import Icon from '../../../../atoms/icons/Icon';
import HandleCallResultModal from '../../../feedback/modal/HandleCallResultModal';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const initialFields = {
  billing_country_id: '',
  billing_fiscal_id: '',
  billing_organization_name: '',
  billing_address: '',
  billing_city: '',
  billing_postal_code: '',
  billing_email: '',
};

const BillingDataLayout = ({ intl, ...props }) => {
  const { appState } = useContext(AppContext);
  const [fields, setFields] = useState(initialFields);
  const [accountSettings, setAccountSettings] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [callSucceeded, setCallSucceeded] = useState(false);
  const [callResponse, setCallResponse] = useState(null);
  const [requestErrors, setRequestErrors] = useState('');
  const [postalCodeError, setPostalCodeError] = useState(false);
  const [nifValidated, setNifValidated] = useState(false);

  useEffect(() => {
    (async () => {
      const { account } = await ContactService.getAccountSettings();
      setAccountSettings(account);
      const keys = Object.keys(initialFields);
      setFields((prev) => ({
        ...prev,
        ...keys.reduce(
          (acc, key) => ({ ...acc, [key]: account[key] || '' }),
          {}
        ),
      }));
      const countriesRes =
        (await StateHelper.fetchCountries(
          appState.accountId,
          () => setRequestErrors(errorKeys.fetchError),
          [
            { key: 'code', value: 'country_code' },
            { key: 'tax_location_id', value: 'tax_location_id' },
          ]
        )) || [];
      setCountries(countriesRes);
    })();
  }, [appState.accountId]);

  const onChangeField = (key, value) => setFields({ ...fields, [key]: value });

  const isButtonDisabled = () => {
    if (nifValidated) return false;
    const {
      billing_organization_name,
      billing_address,
      billing_city,
      billing_fiscal_id,
      billing_country_id,
      billing_email,
    } = fields;
    const {
      billing_fiscal_id: accountFiscalId,
      billing_country_id: accountTaxLocation,
    } = accountSettings;
    return (
      submitting ||
      !billing_organization_name ||
      !billing_address ||
      !billing_city ||
      !billing_fiscal_id ||
      !billing_email ||
      billing_fiscal_id !== accountFiscalId ||
      billing_country_id !== accountTaxLocation
    );
  };

  const responseErrorParser = (response) => {
    if (response.error) {
      const parsed = parse(DOMPurify.sanitize(response.error));
      return parsed;
    } else if (response.errors) {
      const parsed = parse(DOMPurify.sanitize(response.errors[0]['error']));
      return parsed;
    } else {
      return false;
    }
  };
  const onSubmit = async () => {
    setSubmitting(true);
    setIsSubmitted(true);
    setCallResponse('taxDataProcessing');

    const changedFields = Object.keys(fields).reduce((acc, key) => {
      const value =
        typeof fields[key] === 'string' ? fields[key].trim() : fields[key];
      if (value !== accountSettings[key]) acc[key] = value;
      return acc;
    }, {});

    if (!Object.keys(changedFields).length) {
      setSubmitting(false);
      setCallResponse('noChanges');
      setCallSucceeded(true);
      return;
    }

    const response = await ContactService.updateAccountSettings(changedFields);
    setSubmitting(false);

    if (!responseErrorParser(response)) {
      setAccountSettings(response.account);
      setCallResponse('taxDataSuccess');
      setCallSucceeded(true);
    } else {
      const parsed = responseErrorParser(response);
      setCallResponse(parsed);
      setCallSucceeded(false);
    }
  };

  const validateNIF = async () => {
    const iso_code = getCountryIsoCode();
    setCallResponse('validateNIFProcessing');
    setSubmitting(true);
    setIsSubmitted(true);

    if (
      fields.billing_fiscal_id === accountSettings.billing_fiscal_id &&
      fields.billing_country_id === accountSettings.billing_country_id
    ) {
      setSubmitting(false);
      setCallResponse('noChangesNif');
      setCallSucceeded(true);
      return;
    }

    const response = await ContactService.vatNumberLookup(
      fields.billing_fiscal_id,
      iso_code
    );
    setSubmitting(false);

    if (response.valid === true) {
      if (response.address) {
        const parsedAddress = BillingDataHelper.parseAddressObject(
          response.address
        );
        setFields({
          ...fields,
          billing_organization_name: response.name,
          billing_address: parsedAddress.street,
          billing_city: parsedAddress.city,
          billing_postal_code: parsedAddress.postal_code,
        });
        setCallResponse('nifSuccess');
        setCallSucceeded(true);
        setNifValidated(true);
      }
      setCallResponse('nifSuccess');
      setCallSucceeded(true);
      setNifValidated(true);
    } else {
      const invalidVATError = intl.messages['invalidVATError'];
      setCallResponse(invalidVATError);
      setCallSucceeded(false);
    }
  };

  const handlePostalCodeChange = (e) => {
    const value = e.target.value;
    onChangeField('billing_postal_code', value);
    setPostalCodeError(!/^\d{4}-\d{3}$/.test(value));
  };

  const getCountryIsoCode = () => {
    const country = countries.find(
      (c) => c.value === parseInt(fields.billing_country_id)
    );
    return country?.code ?? null;
  };

  return (
    <div id='main-content' className='container --tax-data'>
      <div className='row mx-0 justify-content-center'>
        <div className='col-12 col-lg-9 ps-md-0'>
          <div className='d-flex flex-column w-100'>
            <div className='col-12 px-0'>
              <Paragraph className='text-header h2'>
                {intl.messages['billingDataTitle']}
              </Paragraph>
              <Paragraph className='text-paragraph mt-3 mb-2'>
                <Span className='d-block'>
                  {intl.messages['billingDataDescription']}
                </Span>
              </Paragraph>
              <div className='sep-light mt-2'></div>
            </div>
            <div className='form-container d-flex flex-column w-100 mt-4'>
              <div className='d-flex flex-row w-100 align-items-end justify-content-between mt-4'>
                <div className='selector-wrapper'>
                  <label className='text-label mb-3'>
                    {intl.messages['taxDataFiscalId']}
                  </label>
                  <SelectWithValues
                    data-testid='country-selector'
                    id='tax_location_id'
                    key='tax_location_key'
                    keyName='id'
                    valueName='name'
                    options={countries}
                    value={fields.billing_country_id}
                    onChange={(e) => {
                      onChangeField('billing_country_id', e.target.value);
                      setNifValidated(false);
                    }}
                    disabled={submitting}
                  />
                  {requestErrors && (
                    <div
                      data-testid='country-error-message'
                      className='input-error'
                      id='error-message'
                    >
                      <Icon className='icon fas fa-circle-exclamation' />
                      {requestErrors}
                    </div>
                  )}
                </div>
                <div className='input-wrapper-all flex-grow-1'>
                  <Input
                    data-testid='fiscal-id-field'
                    type='text'
                    id='fiscal_id'
                    value={fields.billing_fiscal_id}
                    onChange={(e) => {
                      onChangeField('billing_fiscal_id', e.target.value);
                      setNifValidated(false);
                    }}
                  />
                </div>
                <div>
                  <Button
                    data-testid='validate-nif-button'
                    className='button button-primary p-0'
                    id='validate_button'
                    label={intl.messages['validateNIF']}
                    onClick={validateNIF}
                    disabled={fields.billing_fiscal_id === ''}
                  >
                    <Icon className='icon fas fa-search me-1' />
                  </Button>
                </div>
              </div>
              <div className='form-container d-flex flex-wrap w-100 mt-4'>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['companyName']}
                    </label>
                    <Input
                      data-testid='company-name-field'
                      id='organization_name'
                      type='text'
                      value={fields.billing_organization_name}
                      onChange={(e) =>
                        onChangeField(
                          'billing_organization_name',
                          e.target.value
                        )
                      }
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['billingDataEmail']}
                    </label>
                    <Input
                      data-testid='email-field'
                      id='billing_email'
                      type='text'
                      value={fields.billing_email}
                      onChange={(e) =>
                        onChangeField('billing_email', e.target.value)
                      }
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['address']}
                    </label>
                    <Input
                      data-testid='address-field'
                      id='address'
                      type='text'
                      value={fields.billing_address}
                      onChange={(e) =>
                        onChangeField('billing_address', e.target.value)
                      }
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['city']}
                    </label>
                    <Input
                      data-testid='city-field'
                      id='city'
                      type='text'
                      value={fields.billing_city}
                      onChange={(e) =>
                        onChangeField('billing_city', e.target.value)
                      }
                      disabled={submitting}
                    />
                  </div>
                </div>
                <div className='col-12 col-md-6 selector-container mt-4'>
                  <div className='selector-wrapper'>
                    <label className='text-label mb-3'>
                      {intl.messages['postalCode']}
                    </label>
                    <Input
                      data-testid='postal-code-field'
                      id='postal_code'
                      type='text'
                      value={fields.billing_postal_code}
                      onChange={handlePostalCodeChange}
                      disabled={submitting}
                    />
                    {postalCodeError && (
                      <div
                        data-testid='postal-code-error'
                        className='input-error'
                        id='postal_code_error'
                      >
                        <Icon className='icon fas fa-circle-exclamation' />
                        {intl.messages['invalidPostalCodeFormat']}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex col-12 px-0 mt-4'>
                <div className='d-flex w-100 flex-column flex-sm-row justify-content-end'>
                  <Button
                    data-testid='save-button'
                    className='button button-primary ms-auto ms-sm-0'
                    id='save_button'
                    label={intl.messages['save']}
                    onClick={onSubmit}
                    disabled={isButtonDisabled() || postalCodeError}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-3 pe-md-0'>
          <Menu {...props} activeCategory={MENU_CATEGORY.ACCOUNT} />
        </div>
      </div>
      {isSubmitted && (
        <HandleCallResultModal
          data-testid='handle-call-result-modal'
          isLoading={submitting}
          succeeded={callSucceeded}
          message={callResponse}
          onClose={() => {
            setIsSubmitted(false);
            setCallResponse(null);
          }}
          showCloseButton
        />
      )}
    </div>
  );
};

export default injectIntl(BillingDataLayout);
